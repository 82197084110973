import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/PaymentMethodsComponentStyle';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function DeliveryMethodsComponent(props) {
  const classes = useStyles();
    const setDeliveryMethod = (e) => {
        componentMethods.setManagerState({deliveryMethod: e.target.value})
    }
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

  return (
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={112}>
            <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup aria-label="deliveryMethod" name="deliveryMethod" value={componentMethods.getManagerState().deliveryMethod} onChange={setDeliveryMethod}>
            {
                componentMethods.getManagerState().deliveryMethods.map((el, idx) => {
                    return <FormControlLabel value={el.deliveryMethodsID.toString()} control={<Radio />} label={componentConstants.language[el.deliveryMethodsName] +  ' +' + (parseInt(el.deliveryMethodsPrice)/100).toFixed(2) + componentConstants.language.LANG_CURRENCY} />
                })
            }
            </RadioGroup>
            </FormControl>
        </Grid>
    </Grid>
  );
}